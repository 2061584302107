import { createSelector } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Root } from "react-dom/client";
import { RootState } from "../app/store";
import { Environment } from "features/environment/types";

export const envApi = createApi({
    reducerPath: "envApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
    }),
    endpoints: (builder) => ({
        getEnv: builder.query<Environment, void>({
            query: (data) => "env.json",
        }),
        getTaskViewData: builder.query<Environment, void>({
            query: (data) => "tasks.json",
        }),
    }),
});

const selectEnvResult = envApi.endpoints.getEnv.select();
const selectTaskViewDataResults = envApi.endpoints.getTaskViewData.select();
export const selectDownloadedEnv = createSelector(
    selectEnvResult,
    (envResult) => envResult?.data
);
export const selectDownloadedTaskViewData = createSelector(
    selectTaskViewDataResults,
    (tasksResult) => tasksResult?.data
);

export const { useGetEnvQuery } = envApi;

import {
    createSlice,
    createEntityAdapter,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Staff } from "./types";
import type { VenuePayloadAction } from "app/types";

type venueId = number;

export interface StaffState {
    staff: Record<venueId, EntityState<Staff, number>>;
}

const staffAdapter = createEntityAdapter<Staff>();

const initialState: StaffState = {
    staff: {},
};

export const staffSlice = createSlice({
    name: "staff",
    initialState,
    reducers: {
        setStaff: (state: StaffState, action: VenuePayloadAction<Staff[]>) => {
            state.staff[action.payload.venueId] = staffAdapter.setAll(
                state.staff[action.payload.venueId] ||
                    staffAdapter.getInitialState(),
                action.payload.data
            );
        },
        resetState: (state, action: PayloadAction) => {
            return initialState;
        },
    },
});

export const { setStaff } = staffSlice.actions;

const staffSelectors = staffAdapter.getSelectors();

export const selectStaff = (state: RootState, venueId?: number) => {
    let staff = staffAdapter.getInitialState();
    if (venueId && state.staff.staff[venueId]) {
        staff = state.staff.staff[venueId];
    }
    return staffSelectors.selectAll(staff);
};

export const selectStaffWithSignoff = (state: RootState, venueId?: number) => {
    return selectStaff(state, venueId).filter(
        (staff) => staff.app_sign_off === 1
    );
};

export const selectHasSetData = (state: RootState, venueId: number) => {
    return selectStaff(state, venueId) !== undefined;
};

export default staffSlice.reducer;

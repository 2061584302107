import { useAppSelector } from "app/hooks";
import { useCallback, useState } from "react";
import type { TaskData, DiaryEntry } from "./types";
import Scheduler from "./Scheduler";
import { generateUniqueId } from "features/environment/util";
import { selectTask, addDiaryEntries, addTaskDiaryEntries } from "./tasksSlice";
import {
    setActiveTasksScreen,
    selectHasBeenIdle,
    selectActiveUser,
    setPendingDiaryEntries,
    setShowUserSelect,
} from "app/appSlice";
import { appSelect, appDispatch } from "app/util";
import { selectHasTaskSignoff } from "features/venue/venueSlice";
import { selectActiveVenueId } from "app/appSlice";

interface GroupChecklistData {
    checklistStatus: Record<number, boolean>;
    onCheck: (taskId: number, checked: boolean) => void;
    onDone: () => void;
    onCompleteAll: () => void;
    onClose: () => void;
}

export default function useGroupChecklist(
    group: TaskData[]
): GroupChecklistData {
    const [checklistStatus, setChecklistStatus] = useState<
        Record<number, boolean>
    >({});
    const hasTaskSignoff = useAppSelector(selectHasTaskSignoff);
    const hasBeenIdle = useAppSelector(selectHasBeenIdle);
    const activeUserId = useAppSelector(selectActiveUser);
    const venueId = useAppSelector(selectActiveVenueId);

    const onCheck = useCallback(
        (taskId: number, checked: boolean) => {
            checklistStatus[taskId] = checked;
            setChecklistStatus({ ...checklistStatus });
        },
        [checklistStatus, setChecklistStatus]
    );

    const onDone = useCallback(() => {
        let completedDiaries: DiaryEntry[] = [];
        let performed = Scheduler.getNowUtc();
        for (const [taskIdString, checked] of Object.entries(checklistStatus)) {
            if (checked && venueId) {
                const taskId = Number(taskIdString);
                const task = appSelect((state) =>
                    selectTask(state, venueId, taskId)
                );
                if (task) {
                    let lastDueCompleted;
                    if (task && task.schedule) {
                        const scheduler = new Scheduler(task);
                        lastDueCompleted = scheduler.getLastDueUtc();
                    } else {
                        lastDueCompleted = Scheduler.getNowUtc();
                    }
                    completedDiaries.push({
                        uuid: generateUniqueId(),
                        taskId: taskId,
                        information: JSON.stringify({}),
                        venueId: venueId || 0,
                        performed,
                        due: lastDueCompleted,
                        userId: activeUserId,
                    });
                }
            }
        }

        if (hasTaskSignoff && (!activeUserId || hasBeenIdle)) {
            appDispatch(setPendingDiaryEntries(completedDiaries));
            appDispatch(setShowUserSelect(true));
        } else {
            appDispatch(addDiaryEntries(completedDiaries));
            appDispatch(addTaskDiaryEntries(completedDiaries));
            appDispatch(setActiveTasksScreen({}));
        }
    }, [checklistStatus, activeUserId, hasTaskSignoff, hasBeenIdle, venueId]);

    const onCompleteAll = useCallback(() => {
        for (let task of group) {
            onCheck(task.id, true);
        }
        setTimeout(() => {
            onDone();
        }, 200);
    }, [group, onCheck, onDone]);

    const onClose = useCallback(() => {
        appDispatch(setActiveTasksScreen({}));
    }, []);

    return {
        checklistStatus,
        onCheck,
        onDone,
        onCompleteAll,
        onClose,
    };
}

import React, { ChangeEvent } from "react";
import { ComponentProps } from "./types";
import formStyles from "./Form.module.css";
import styles from "./TextLineEntry.module.css";
import { marked } from "marked";

interface Props extends ComponentProps {}

export default function TextLineEntry(props: Props) {
    let error;
    if (props.error) {
        error = <p className={formStyles.error}>{props.error.message}</p>;
    }
    return (
        <div className={styles.TextLineEntry}>
            <p className={formStyles.label}>{props.label}</p>
            <input
                className={styles.input}
                name={props.model}
                value={props.value}
                placeholder={props.description ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    props.onChange(props.model, e.target.value);
                }}
            />
            {props.description && (
                <p
                    className={formStyles.description}
                    dangerouslySetInnerHTML={{
                        __html: marked.parse(props.description),
                    }}
                />
            )}
            {error}
        </div>
    );
}

import { z } from "zod";
import { FormComponentData } from "./types";

export const popoverInformationSchema = z.object({
    model: z.string(),
    suffix: z.string().optional(),
});

export const screenSchema = z.union([
    z.literal("init"),
    z.literal("login"),
    z.literal("tasks"),
    z.literal("due"),
    z.literal("events"),
    z.literal("admin"),
    z.literal("diary"),
    z.literal("taskGroup"),
    z.literal("checkList"),
    z.literal("temperatureList"),
    z.literal("task"),
    z.literal("settings"),
]);

export const dataSourceSchema = z.union([
    z.literal("staff"),
    z.literal("staffWithSignoff"),
    z.literal("thermometerTasks"),
    z.literal("suppliers"),
]);

export const dataSourceItemSchema = z.object({
    value: z.union([z.number(), z.string()]),
    label: z.string(),
    id: z.union([z.string(), z.number()]).optional(),
});

export const dataSourcesSchema = z.object({
    staff: z.array(dataSourceItemSchema),
    staffWithSignoff: z.array(dataSourceItemSchema),
    suppliers: z.array(dataSourceItemSchema),
    thermometerTasks: z.array(dataSourceItemSchema),
});

export const dataSourceValueSourceSchema = z.union([
    z.literal("value"),
    z.literal("label"),
]);

export const labelAlternativeSchema = z.object({
    label: z.string(),
    predicates: z.array(z.string()),
});

export const componentOptionSchema = z.object({
    label: z.string(),
    value: z.union([z.number(), z.string(), z.boolean()]),
    ignore: z.boolean().optional(),
    default: z.boolean().optional(),
    manualEntry: z.boolean().optional(),
    custom: z.boolean().optional(),
});

const comparatorSchema = z.union([
    z.literal("=="),
    z.literal("!="),
    z.literal("<"),
    z.literal(">"),
]);

const propertySchema = z.literal("length");

export const calculationSchema = z.union([
    z.literal("max"),
    z.literal("min"),
    z.literal("subtract"),
    z.literal("invalidReading"),
    z.literal("outlier"),
]);

export const componentCalculationSchema = z.object({
    models: z.array(z.string()),
    calculation: calculationSchema,
    argument: z.any().optional(),
});

export const operationSchema = z.union([
    z.literal("assign"),
    z.literal("storeItem"),
    z.literal("resetShowErrors"),
]);

export const formComponentDataSchema: z.ZodSchema<FormComponentData> = z.lazy(
    () =>
        z.object({
            type: z.string(),
            id: z.string().optional(),
            model: z.string().optional(),
            selfValue: z.any().optional(),
            label: z.string().optional(),
            labelAlternatives: z.array(labelAlternativeSchema).optional(),
            start: z.number().optional(),
            end: z.number().optional(),
            increment: z.number().optional(),
            default: z.any().optional(),
            options: z.array(componentOptionSchema).optional(),
            showPredicates: z.array(z.string()).optional(),
            requiredPredicates: z.array(z.string()).optional(),
            required: z.boolean().optional(),
            requiredMessage: z.string().optional(),
            problemPredicates: z.array(z.string()).optional(),
            invalidPredicates: z.array(z.string()).optional(),
            problemError: z.string().optional(),
            invalidError: z.string().optional(),
            dataSource: dataSourceSchema.optional(),
            dataSourceValue: z
                .union([z.literal("value"), z.literal("label")])
                .optional(),
            dataSourceIdModel: z.string().optional(),
            components: z.array(formComponentDataSchema).optional(),
            useSensor: z.boolean().optional(),
            onclick: operationSchema.optional(),
        })
);

export const extraDataSchema = z.object({
    model: z.string(),
    value: z.any(),
    predicates: z.array(z.string()).optional(),
});

export const componentPredicateSchema = z.object({
    value: z.any(),
    model: z.string().optional(),
    property: propertySchema.optional(),
    comparator: comparatorSchema.optional(),
    dataSource: dataSourceSchema.optional(),
    calculation: calculationSchema.optional(),
    calculationModels: z.array(z.string()).optional(),
    calculationArgument: z.number().optional(),
});

export const componentPredicateDefinition = z.object({
    issue: z.boolean(),
    conditions: z.array(componentPredicateSchema),
});

export const formViewSchema = z.object({
    name: z.string().optional(),
    showMethod: z.boolean().optional(),
    showProving: z.boolean().optional(),
    showBatchNumber: z.boolean().optional(),
    components: z.array(formComponentDataSchema),
    calculations: z.record(componentCalculationSchema).optional(),
    predicates: z
        .record(
            z.array(componentPredicateSchema).or(componentPredicateDefinition)
        )
        .optional(),
    itemsKey: z.string().optional(),
    extraData: z.array(extraDataSchema).optional(),
});

export const categoryViewSchema = z.object({
    name: z.string(),
    groupView: screenSchema.optional(),
    alwaysGroup: z.boolean().optional(),
    showPopoverInformation: popoverInformationSchema.optional(),
    taskView: formViewSchema,
    taskIcon: z
        .union([
            z.literal("BadgeCheck"),
            z.literal("BowlRice"),
            z.literal("CommentQuote"),
            z.literal("Droplet"),
            z.literal("FaceThermometer"),
            z.literal("FireSmoke"),
            z.literal("PawSimple"),
            z.literal("PenToSquare"),
            z.literal("Snowflake"),
            z.literal("Sparkles"),
            z.literal("SquareCheck"),
            z.literal("TemperatureHalf"),
            z.literal("TemperatureList"),
            z.literal("TemperatureSnow"),
            z.literal("TemperatureSun"),
            z.literal("TriangleExclamation"),
            z.literal("Truck"),
            z.literal("Virus"),
            z.literal("Wrench"),
            z.undefined(),
        ])
        .optional(),
});

import {
    configureStore,
    combineReducers,
    ThunkAction,
    Action,
} from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    createMigrate,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    PersistConfig,
    KEY_PREFIX,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reportException } from "reportHandler";
import appSliceReducer from "./appSlice";
import loginTokenSlice from "features/loginToken/loginTokenSlice";
import tasksReducer from "features/tasks/tasksSlice";
import staffReducer from "features/staff/staffSlice";
import suppliersReducer from "features/suppliers/suppliersSlice";
import envReducer from "features/environment/envSlice";
import venueReducer from "features/venue/venueSlice";
import { envApi } from "services/env";
import { appServiceApi } from "services/appService";
import { authServiceApi } from "services/authService";
import { listenerMiddleware } from "./listeners";
import RedundantStore from "features/dataRedundancy/RedundantStore";
import IDBRedundantStore from "features/dataRedundancy/IDBRedundantStore";
import { isTesting } from "./util";
import IDBPersistStore from "./idbStore";
import { getStoredState } from "redux-persist";

const rootReducer = combineReducers({
    app: appSliceReducer,
    loginToken: loginTokenSlice,
    tasks: tasksReducer,
    staff: staffReducer,
    suppliers: suppliersReducer,
    environment: envReducer,
    venue: venueReducer,
    [envApi.reducerPath]: envApi.reducer,
    [appServiceApi.reducerPath]: appServiceApi.reducer,
    [authServiceApi.reducerPath]: authServiceApi.reducer,
});

const migrations = {
    1: (state: any) => {
        return state;
    },
    2: (state: any) => {
        return {
            ...state,
            tasks: {
                ...state.tasks,
                taskDiaryEntries: {},
            },
        };
    },
    3: (state: any) => {
        return {
            ...state,
            app: {
                ...state.app,
                serverErrors: {},
            },
        };
    },
    4: (state: any) => {
        return {
            ...state,
            app: {
                ...state.app,
                diaryFileQueue: [],
            },
        };
    },
    6: (state: any) => {
        return {
            ...state,
            app: {
                ...state.app,
                networkQueue: {},
            },
        };
    },
    7: (state: any) => {
        const activeVenueId = state.app.activeVenueId;
        let newState = state;
        if (activeVenueId && !state.tasks.tasks.hasOwnProperty(activeVenueId)) {
            newState = {
                ...state,
                tasks: {
                    ...state.tasks,
                    tasks: {
                        [activeVenueId]: state.tasks.tasks,
                    },
                    tasksPerformed: {
                        [activeVenueId]: state.tasks.tasksPerformed,
                    },
                    categories: {
                        [activeVenueId]: state.tasks.categories,
                    },
                },
            };
        }

        return newState;
    },
    8: (state: any) => {
        const activeVenueId = state.app.activeVenueId;
        let newState = state;
        if (activeVenueId && !state.venue.hasOwnProperty(activeVenueId)) {
            newState = {
                ...state,
                venue: {
                    [activeVenueId]: state.venue,
                },
            };
        }

        if (activeVenueId && !state.staff.staff.hasOwnProperty(activeVenueId)) {
            newState = {
                ...newState,
                staff: {
                    staff: {
                        [activeVenueId]: newState.staff.staff,
                    },
                },
            };
        }

        if (
            activeVenueId &&
            !state.suppliers.suppliers.hasOwnProperty(activeVenueId)
        ) {
            newState = {
                ...newState,
                suppliers: {
                    suppliers: {
                        [activeVenueId]: newState.suppliers.suppliers,
                    },
                },
            };
        }

        return newState;
    },
    9: (state: any) => {
        let loginError = state.loginToken.loginError;
        if (loginError) {
            loginError = {
                error: loginError,
                type: "app",
            };
        }
        return {
            ...state,
            loginToken: {
                ...state.loginToken,
                loginError,
            },
        };
    },
};

const persistConfig = {
    key: "root",
    version: 9,
    storage: new IDBPersistStore(),
    serialize: false,
    deserialize: false,
    blacklist: [appServiceApi.reducerPath, envApi.reducerPath],
    migrate: createMigrate(migrations, { debug: false }),
    onWriteFail: (error: Error) => {
        console.error("Error writing to storage: %o", error);
        reportException("redux-persist onWriteFail", error);
    },
    getStoredState: async (config: PersistConfig<any>): Promise<any | void> => {
        let state = await getStoredState(config);
        if (!state) {
            let localStorageConfig = {
                ...config,
                storage: storage,
                deserialize: null,
            };
            state = await getStoredState(localStorageConfig);
            if (state) {
                const storageKey = `${KEY_PREFIX}${config.key}`;
                window.localStorage.removeItem(storageKey);
            }
        }

        return Promise.resolve(state);
    },
    debug: true,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (addListeners?: boolean) => {
    const store = configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) => {
            let defaultMiddleware = getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        FLUSH,
                        REHYDRATE,
                        PAUSE,
                        PERSIST,
                        PURGE,
                        REGISTER,
                    ],
                },
            }).concat(
                appServiceApi.middleware,
                envApi.middleware,
                authServiceApi.middleware
            );
            if (addListeners !== false) {
                defaultMiddleware = defaultMiddleware.prepend(
                    listenerMiddleware.middleware
                ) as unknown as typeof defaultMiddleware;
            }

            return defaultMiddleware;
        },
    });

    return store;
};

const addListeners = !isTesting();
let _store = setupStore(addListeners);

export function getStore(): ReturnType<typeof setupStore> {
    return _store;
}

export function setStore(store: ReturnType<typeof setupStore>) {
    _store = store;
}

let _persistor = persistStore(getStore());
export function getPersistor(
    store?: ReturnType<typeof setupStore>
): ReturnType<typeof persistStore> {
    if (store) {
        return persistStore(store);
    }
    return _persistor;
}

let _redundantStore = new IDBRedundantStore();

export function getRedundantStore(): RedundantStore {
    return _redundantStore;
}

export function setRedundantStore(store: RedundantStore) {
    _redundantStore = store;
}

export type AppDispatch = typeof _store.dispatch;
export type RootState = ReturnType<typeof _store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
